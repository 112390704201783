<script setup lang="ts">
import { twMerge } from "tailwind-merge";
import { Dialog as HDialog, DialogPanel as HDialogPanel, TransitionRoot, TransitionChild } from "@headlessui/vue";

const emit = defineEmits(["update:modelValue", "close"]);
const props = withDefaults(
  defineProps<{
    modelValue?: boolean;
    appear?: boolean;
    overlay?: boolean;
    transition?: boolean;
    preventClose?: boolean;
    fullscreen?: boolean;
    dialogClass?: string;
  }>(),
  {
    modelValue: false,
    appear: false,
    overlay: true,
    transition: true,
    preventClose: false,
    fullscreen: false,
    dialogClass: "",
  },
);

const isOpen = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const transitionClass = computed(() => {
  if (!props.transition) {
    return {};
  }

  // Syntax for `<TransitionRoot>` component https://headlessui.com/vue/transition#basic-example
  return {
    enter: "ease-out duration-300",
    enterFrom: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
    enterTo: "opacity-100 translate-y-0 sm:scale-100",
    leave: "ease-in duration-200",
    leaveFrom: "opacity-100 translate-y-0 sm:scale-100",
    leaveTo: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
  };
});

const dialogPanelClass = computed(() =>
  twMerge(
    "relative text-left rtl:text-right overflow-hidden w-full flex flex-col",
    "bg-white dark:bg-neutral-dark-700",
    "shadow-xl",
    props.dialogClass,
    props.fullscreen ? "w-screen" : "sm:max-w-lg",
    props.fullscreen ? "h-screen" : "",
    props.fullscreen ? "rounded-none" : "rounded-lg",
    props.fullscreen ? "m-0" : "sm:my-8",
  ),
);

// Syntax for `<TransitionRoot>` component https://headlessui.com/vue/transition#basic-example
const overlayTransition = {
  enter: "ease-out duration-300",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
  leave: "ease-in duration-200",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0",
};

function close(value: boolean) {
  isOpen.value = value;

  emit("close");
}
</script>

<template>
  <TransitionRoot :appear="props.appear" :show="isOpen" as="template">
    <HDialog class="relative z-50" @close="(e) => !props.preventClose && close(e)">
      <TransitionChild v-if="props.overlay" as="template" :appear="appear" v-bind="overlayTransition">
        <div class="fixed inset-0 transition-opacity bg-gray-200/75 dark:bg-gray-800/75" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          :class="[
            'flex min-h-full items-end sm:items-center justify-center text-center',
            !props.fullscreen && 'p-4 sm:p-0',
          ]"
        >
          <TransitionChild as="template" :appear="appear" v-bind="transitionClass">
            <HDialogPanel :class="dialogPanelClass">
              <slot />
            </HDialogPanel>
          </TransitionChild>
        </div>
      </div>
    </HDialog>
  </TransitionRoot>
</template>
