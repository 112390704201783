import type { ShallowRef } from "vue";
import type { UModal } from "#components";
import { createSharedComposable } from "@vueuse/core";

type ComponentProps<T> = T extends new () => { $props: infer P }
  ? NonNullable<P>
  : T extends (props: infer P, ...args: any) => any
    ? P
    : {};

type ModalProps = InstanceType<typeof UModal>["$props"]; 

export interface ModalState {
  component: Component | string;
  props: ModalProps;
}

export const modalInjectionKey: InjectionKey<ShallowRef<ModalState>> = Symbol("ui.modal");

function _useModal() {
  const modalState = inject(modalInjectionKey) as ShallowRef<ModalState>;

  const isOpen = ref(false);

  function open<T extends Component>(component: T, props?: ModalProps & ComponentProps<T>) {
    modalState.value = { component, props: props ?? {} };
    isOpen.value = true;
  }

  function close() {
    isOpen.value = false;
    modalState.value = { component: "div", props: {} };
  }

  /**
   * Allows updating the modal props
   */
  function patch<T extends Component = {}>(props: Partial<ModalProps & ComponentProps<T>>) {
    modalState.value = { ...modalState.value, props: { ...modalState.value.props, ...props, } };
  }

  return { isOpen, open, close, patch, };
}

export const useModal = createSharedComposable(_useModal);
