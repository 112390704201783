import type { TSportType } from "~/types";

export type TEnvironment = "local" | "development" | "staging" | "production";
export interface DirectusRonaldoApplicationConfig {
  enable_leagues_flow: boolean;
  enable_join_team_flow: boolean;
  enable_playon_organizations: boolean;
  enable_athlete_add_season: boolean;
  enable_event_team_stats_tab: boolean;
  enable_event_athlete_stats_tab: boolean;
  enable_event_stats_export_to_pdf: boolean;
  enable_team_manage_rosters_vs_deep_link: boolean;
  enable_team_overview_tab: boolean;
  enable_team_stats_tab: boolean;
  enable_team_athletes_tab: boolean;
  enable_team_video_upload: boolean;
  enable_team_video_import: boolean;
  enable_league_standings_tab: boolean;
  enable_league_leaderboards_tab: boolean;
  enable_league_athletes_tab: boolean;
  enable_athletes_index_page: boolean;
  enable_athletes_create_page: boolean;
  enable_athletes_edit_page: boolean;
  enable_athletes_delete: boolean;
  enable_athlete_overview_tab: boolean;
  enable_athlete_bio_tab: boolean;
  enable_athlete_videos_tab: boolean;
  enable_athlete_stats_tab: boolean;
  enable_athlete_charts_tab: boolean;
  enable_athletes_merge: boolean;
}
export interface DirectusFeatureRule {
  feature_name: string;
  enable_for_environments: TEnvironment[];
  enable_for_super_admins?: boolean;
  enable_for_users?: string[];
  enable_for_sport_type?: TSportType[];
}
export type DirectusRonaldoConfig = DirectusRonaldoApplicationConfig & {
  feature_rules?: DirectusFeatureRule[];
  replacement?: Partial<Record<TEnvironment, Partial<DirectusRonaldoApplicationConfig>>>;
};

export default function formatDirectusConfig(
  defaultConfig: DirectusRonaldoApplicationConfig,
  newConfig: DirectusRonaldoConfig,
): DirectusRonaldoApplicationConfig {
  const appEnv = useRuntimeConfig().public.appEnv as TEnvironment;
  const isLocal = window.location.hostname === "localhost" || appEnv === "local";

  // In case if local environment is used - use only data from app.config.ts
  if (isLocal) {
    return Object.assign(defaultConfig);
  }

  const envConfig = (newConfig.replacement && newConfig.replacement[appEnv]) || {};
  const final = JSON.parse(JSON.stringify(newConfig));

  // Removing redundant props from the final config
  delete final.id;
  delete final.replacement;

  return Object.assign(defaultConfig, final, envConfig);
}
