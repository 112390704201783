import type { IdTokenResult, User } from "firebase/auth";
import type { IUser } from "~/types";

export default function formatUser(user: User, idTokenResult: IdTokenResult): IUser {
  const cachedUser = unref(useUser());

  /**
   * Will be updated after login
   */
  const hasLeaguesAccess = cachedUser?.hasLeaguesAccess || false;
  const hasOrganizationsAccess = cachedUser?.hasOrganizationsAccess || false;

  return {
    uid: user.uid,
    email: user.email,
    fullName: user.displayName,
    phoneNumber: user.phoneNumber,
    hasPasswordProvider: Boolean(user.providerData.find((provider) => provider.providerId === "password")),
    hasEmailVerified: user.emailVerified,
    hasB2BAccount: Boolean(idTokenResult.claims.isB2BUser),
    hasVidswapAccount: Boolean(idTokenResult.claims.isVsUser),
    hasSuperAdminRole: Boolean(idTokenResult.claims.group && idTokenResult.claims.group === "admin"),
    hasLeaguesAccess,
    hasOrganizationsAccess,
    teamsSports: []
  };
}
