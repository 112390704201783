<script setup lang="ts">
import type { IPlaylistItem, IPlaylist } from "~/types";
import { formatTime } from "~/helpers/format-time";
import { PLAYLIST_TYPE } from "~/constants";

const props = defineProps<{
  playlist: null | IPlaylist;
  playlistItem: IPlaylistItem;
  activeItem: null | IPlaylistItem;
  isSelected: boolean;
  isVideoPaused?: boolean;
}>();
const { t } = useI18n();
const localePath = useLocalePath();
const router = useRouter();
const segment = useSegment();
const emit = defineEmits<{
  (event: "addToPlaylist", playlistItem: IPlaylistItem): void;
  (event: "playlist-item-click", playlistItem: IPlaylistItem): void;
  (event: "select", selected: boolean): void;
  (event: "close-player"): void;
  (event: "delete", playlistItem: IPlaylistItem): void;
  (event: "edit", playlistItem: IPlaylistItem): void;
}>();
const baseURL = useRuntimeConfig().app.baseURL;
const isPrivatePlaylist = props.playlist?.type === PLAYLIST_TYPE.PRIVATE;
const isActive = computed(() => props.activeItem?.id === props.playlistItem.id);
const thumbnailStateIcon = computed(() => {
  if (isActive.value && props.isVideoPaused) {
    return "play";
  } else if (isActive.value) {
    return "pause-nocircle";
  }
  return "play";
});

function saveToPlaylist() {
  segment.track("Save Tag to Playlist", formatTrackPlaylistItem(props.playlistItem));

  emit("addToPlaylist", props.playlistItem);
}
function openEventPage() {
  segment.track("Go to Tag Match Page", formatTrackPlaylistItem(props.playlistItem));

  emit("close-player");
  nextTick(() => router.push(localePath(`/events/${props.playlistItem.playlist_source?.id}`)));
}
</script>

<template>
  <div
    class="flex items-center justify-between h-[60px] group hover:bg-neutral-light-50 hover:dark:bg-neutral-dark-600 mb-1 ltr:pr-4 rtl:pl-4 cursor-pointer"
    :class="{ 'bg-neutral-light-50 dark:bg-neutral-dark-500': isActive }"
    @click="emit('playlist-item-click', props.playlistItem)"
  >
    <div class="flex items-center basis-4/6">
      <UIcon name="reorder" class="handle child opacity-0 group-hover:opacity-100 transition-opacity duration-200 cursor-move text-neutral-light-700" />
      <div class="flex items-center gap-4 ltr:pl-2 rtl:pr-2">
        <UCheckbox :name="`playlist-item-${props.playlistItem.id}`" :model-value="props.isSelected" @update:model-value="emit('select', $event)" />
        <div class="flex relative">
          <div
            class="w-full h-full absolute group-hover:bg-[#00000066] z-[7] flex items-center justify-center rounded-sm"
            :class="{ 'bg-[#00000066]': isActive }"
          >
            <RIcon
              class="text-sm cursor-pointer text-white opacity-0 group-hover:opacity-100"
              :class="{ 'opacity-100': isActive }"
              :name="thumbnailStateIcon"
            />
          </div>
          <RImage
            class="object-cover w-[72px] h-10 rounded-sm relative shrink-0"
            :src="props.playlistItem.playlist_source.urls.poster"
            :fallback-src="`${baseURL}images/no_poster.jpg`"
          />
        </div>
        <span class="text-sm font-medium text-black dark:text-white">{{ props.playlistItem.title || "Tag" }}</span>
      </div>
    </div>
    <div class="flex gap-4 items-center justify-end basis-2/6">
      <IconPlaying v-if="isActive" :active="!props.isVideoPaused" class="inline-block text-neutral-light-700 pb-0.5" />
      <div v-if="props.playlistItem.playerNumber && !isActive" class="flex gap-1">
        <UIcon name="t-shirt" class="inline-block text-green-500 pt-0.5" />
        <span v-tooltip="`#${props.playlistItem.playerNumber}`" class="font-medium text-sm dark:text-white max-w-12 truncate">#{{ props.playlistItem.playerNumber }}</span>
      </div>
      <div v-if="props.playlistItem.playerNumber && !isActive" class="h-6 w-[1px] bg-neutral-light-200" />
      <span v-if="!isActive" class="text-end text-sm w-10 font-medium dark:text-white">
        {{ formatTime(props.playlistItem.startTime) }}
      </span>
      <UMenu>
        <UList>
          <UListItem icon="add" :text="t('labels.save_to_playlist')" @click="saveToPlaylist()" />
          <UListItem icon="vs" :text="t('labels.go_to_match_page')" @click="openEventPage()" />
          <UListItem v-if="isPrivatePlaylist" icon="edit" :text="t('labels.edit')" @click="() => emit('edit', props.playlistItem)" />
          <UListItem v-if="isPrivatePlaylist" :icon="{ name: 'delete', class: 'text-red-500 dark:text-red-500' }" class="!text-red-500" :text="t('labels.delete')" @click="() => emit('delete', props.playlistItem)" />
        </UList>
      </UMenu>
    </div>
  </div>
</template>
