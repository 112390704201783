<script setup lang="ts">
import type { IBasicVideo, IClip, IEvent, IPlaylist } from "~/types";
import type { PxlIcon } from "~/components/U/Icon";
import { UModalConfirm } from "#components";
import { PLAYLIST_TYPE, VIDEO_TYPE } from "~/constants";

const props = defineProps<{
  video?: IEvent | IBasicVideo | null;
  playlist?: null | IPlaylist;
}>();

const emit = defineEmits<{
  (event: "deleted"): void;
}>();

const user = useUser();
const { t } = useI18n();
const toast = useToast();
const modal = useModal();

const isEvent = (vid?: IPlaylist | IEvent | IBasicVideo | null): vid is IEvent => vid?.type === VIDEO_TYPE.EVENT;
const isClip = (vid?: IPlaylist | IEvent | IBasicVideo | null): vid is IClip => vid?.type === VIDEO_TYPE.CLIP;

function onDeleteConfirm(): Promise<void> {
  if (props.playlist)
    return deletePlaylist(props.playlist.id).then(() => toast.success(t("pages.playlists.modal.playlist_delete_success"), t("pages.events.event_delete_successful_title")));
  if (props.video) {
    if (isEvent(props.video)) return deleteEvent(props.video.id).then(() => toast.success(t("pages.events.event_delete_successful"), t("pages.events.event_delete_successful_title")));
    if (isClip(props.video)) return deleteUserClipById(props.video.id).then(() => toast.success(t("labels.clip_delete_success"), t("pages.events.event_delete_successful_title")));
  }

  throw new Error("Unknown video type");
}

const isDisabled = computed<boolean>(() => {
  if (props.playlist) return props.playlist.type === PLAYLIST_TYPE.SYSTEM;
  return false;
});
const modalIcon = computed<PxlIcon>(() => {
  if (props.playlist) return "playlist";
  if (props.video && isClip(props.video)) return "clip";

  return "matches";
});
const modalHeading = computed<string>(() => {
  if (props.playlist) return t("pages.playlists.modal.playlist_delete_confirm");
  if (props.video) {
    if (isEvent(props.video)) return t("pages.events.event_delete_confirm_title");
    if (isClip(props.video)) return t("labels.clip_delete_confirm");
  }

  return "";
});

const isAvailable = computed<boolean>(() => {
  if (props.playlist) return props.playlist.permissions.canDelete;
  if (isEvent(props.video) || isClip(props.video)) return props.video.permissions.canDelete;

  return false;
});

function onClick() {
  modal.open(UModalConfirm, {
    heading: modalHeading.value,
    icon: modalIcon.value,
    variant: "delete",
    confirmFn: () => onDeleteConfirm().then(onSuccess).catch(onError),
    confirmLabel: t("labels.delete")
  });
}

function onError() {
  toast.error(t("pages.events.event_delete_error"));
}

function onSuccess() {
  emit("deleted");
}
</script>

<template>
  <UListItem
    v-if="user && isAvailable"
    :icon="{ name: 'delete', class: 'text-red-500 dark:text-red-500' }"
    class="!text-red-500"
    :text="t('labels.delete')"
    :disabled="isDisabled"
    :on-click="onClick"
  />
</template>
