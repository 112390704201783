<script setup lang="ts">
import type { IEvent, IPlaylist, IPlaylistItem, ITag } from "~/types";

const props = defineProps<{
  event?: IEvent;
  playlist?: null | IPlaylist;
  tags: ITag[] | IPlaylistItem[];
  isClipFromPlaylist?: boolean;
}>();
const emit = defineEmits<{
  (event: "close"): void;
}>();

const { t } = useI18n();
const toast = useToast();
const segment = useSegment();

const options = ref({
  name: "",
  music: "https://content.you.pixellot.tv/static/internal/clipping/soundtrack/electronic.mp3",
  keep_audio: false,
  add_music: true,
  add_cross_fade: false,
  add_player_number: false,
});
const activePlayerNumber = computed(() => props.tags[0].playerNumber);
const hasTagsForSamePlayer = computed(() =>
  [...props.tags].every((t) => t.playerNumber && t.playerNumber === activePlayerNumber.value),
);
const selectedClipType = ref<"clip" | "highlight">("clip");
const isHighlightTypeSelected = computed(() => selectedClipType.value === "highlight");
const isCrossFadeOptionDisabled = computed(() => props.tags.length <= 1);
const { state: musicOptions, isLoading: isLoadingMusicOptions } = useAsyncState(() => getEventClipMusicOptions(), []);
const { isLoading, execute: onConfirm } = useAsyncState(
  () => {
    const payload = {
      tags: props.tags,
      name: options.value.name,
      soundtrack: options.value.add_music
        ? musicOptions.value.find((o) => o.value === options.value.music)?.label?.toLowerCase() || "electronic"
        : "",
      sourceAudioVolume: options.value.keep_audio ? 1 : 0,
    } as const;

    segment.track(
      "Create Clip from Tags",
      formatTrackClipFromTags(
        payload.name,
        payload.tags.length,
        payload.soundtrack,
        payload.sourceAudioVolume === 1,
        options.value.add_cross_fade,
      ),
    );

    if (props.isClipFromPlaylist && props.playlist) {
      return createEventClipFromPlaylistWithOptions(props.playlist, {
        ...payload,
        transition: options.value.add_cross_fade ? "crosswarp" : null,
      });
    }

    if (isHighlightTypeSelected.value && props.event) {
      return createEventPlayerHighlightFromTagsWithOptions(props.event, {
        ...payload,
        teamColor: "#233C5F", // TODO: Pass the correct team color when will have it from BE.
        playerNumber: activePlayerNumber.value as string,
        transition: options.value.add_cross_fade ? "crossfade" : null,
      });
    }

    if (props.event) {
      return createEventClipFromTagsWithOptions(props.event, {
        ...payload,
        transition: options.value.add_cross_fade ? "crosswarp" : null,
      });
    }

    return Promise.reject(new Error("Unknown Error! Please try again later"));
  },
  null,
  { immediate: false, onSuccess, onError },
);
const {
  toggle: togglePlayingMusic,
  pause: pausePlayingMusic,
  isPlaying: isPlayingMusic,
} = useAudio(computed(() => options.value.music));
const isConfirmDisabled = computed(() => {
  const isNameValid = isHighlightTypeSelected.value
    ? true
    : options.value.name && options.value.name.length >= 2 && options.value.name.length <= 50;

  return isLoading.value || !isNameValid;
});

function onClose() {
  pausePlayingMusic();
  emit("close");
}
function onSuccess() {
  toast.success(t("pages.editor.add_clip_toast_success_text"), t("pages.editor.add_clip_toast_success"));
  pausePlayingMusic();
  emit("close");

  if (props.event) {
    segment.track("Highlight From Tags Create Highlight", {
      ...formatTrackEvent(props.event),
      ...{ highlightType: isHighlightTypeSelected.value ? "player-highlight" : "clip" },
    });
  }
}
function onError(err: unknown) {
  toast.error(parseErrorMessage(err, { t }));

  if (props.event) {
    segment.track("Highlight Generation Failure", {
      ...formatTrackEvent(props.event),
      ...{ highlightType: isHighlightTypeSelected.value ? "player-highlight" : "clip" },
    });
  }
}
</script>

<template>
  <UModal
    appear
    :model-value="true"
  >
    <div class="p-4 pb-3 flex items-center justify-end">
      <UButton
        title="Close"
        variant="link-secondary"
        icon="close"
        class="h-5 w-5"
        @click="emit('close')"
      />
    </div>

    <div class="md:px-10 md:pb-10">
      <h2
        id="modalHeading"
        class="text-2xl font-bold text-black dark:text-white"
      >
        {{ t('pages.editor.add_clip') }}
      </h2>

      <div class="flex flex-col">
        <div class="flex flex-col gap-6 pt-6 text-black dark:text-white">
            <div v-if="!isClipFromPlaylist" class="flex flex-col gap-6 pt-6">
            <p class="text-sm font-bold">
                {{ t("labels.type") }}
            </p>

            <URadio v-model="selectedClipType" name="type" :label="t('pages.editor.add_clip')" value="clip" />
            <URadio
                v-model="selectedClipType"
                block
                name="type"
                value="highlight"
                :disabled="!hasTagsForSamePlayer"
                :label="hasTagsForSamePlayer ? t('pages.editor.create_athlete_highlight_for_number') : t('pages.editor.create_athlete_highlight')"
            >
                <template #label="{ label }">
                <div class="flex items-center gap-3 grow justify-between">
                    <div class="flex items-center gap-1">
                    <span>{{ label }}</span>
                    <UIcon name="t-shirt" class="inline-block text-green-500" />
                    <span v-if="hasTagsForSamePlayer">#{{ activePlayerNumber }}</span>
                    </div>
                    <UIcon v-tooltip="hasTagsForSamePlayer ? t('pages.editor.create_athlete_highlight_explanation') : t('pages.editor.create_athlete_highlight_from_same_tags_tip')" name="info" />
                </div>
                </template>
            </URadio>

            <UDivider lighter />
            </div>

            <UFormGroup :label="t('pages.editor.clip_name')" name="clip_name">
            <UInput v-model="options.name" :placeholder="t('pages.editor.clip_name_placeholder')" :disabled="isHighlightTypeSelected" />
            </UFormGroup>

            <UDivider lighter />

            <p class="text-sm font-bold">
            {{ t("labels.sound_options") }}
            </p>

            <div class="flex items-center justify-between">
            <label class="flex items-center gap-3" for="checkbox-clip-create-keep-audio">
                <UIcon name="sound-on" />
                <span class="text-sm font-medium">{{ t("labels.keep_recorded_audio") }}</span>
            </label>
            <UCheckbox v-model="options.keep_audio" name="checkbox-clip-create-keep-audio" />
            </div>

            <div class="flex items-center justify-between">
            <label class="flex items-center gap-3" for="checkbox-clip-create-add-music">
                <UIcon name="music" />
                <span class="text-sm font-medium">{{ t("labels.add_bg_music") }}</span>
            </label>
            <UCheckbox v-model="options.add_music" name="checkbox-clip-create-add-music" />
            </div>

            <USelect
            v-model="options.music"
            :disabled="!options.add_music || isLoadingMusicOptions"
            :options="musicOptions"
            :leading-icon="{ name: isPlayingMusic ? 'pause' : 'play-circle', onClick: () => togglePlayingMusic() }"
            />

            <UDivider lighter />

            <p class="text-sm font-bold">
            {{ t("labels.graphic_options") }}
            </p>

            <div class="flex items-center justify-between">
            <label
                class="flex items-center gap-3"
                for="checkbox-clip-create-add-cross-fade"
                :class="{ 'pxl-disabled': isCrossFadeOptionDisabled }"
            >
                <UIcon name="crossfade" />
                <span class="text-sm font-medium">{{ t("labels.crossfade_between_clips") }}</span>
            </label>
            <UIcon v-if="isCrossFadeOptionDisabled" v-tooltip="t('pages.editor.crossfade_option_disabled_explanation')" name="info" />
            <UCheckbox v-else v-model="options.add_cross_fade" name="checkbox-clip-create-add-cross-fade" />
            </div>
        </div>


        <UButtonGroupConfirmCancel
            class="mt-6"
            cancel-variant="secondary"
            :cancel-label="t('labels.cancel')"
            :confirm-disabled="isConfirmDisabled"
            :confirm-label="t('pages.editor.add_clip')"
            :on-cancel-click="onClose"
            :on-confirm-click="onConfirm"
        />
      </div>
    </div>
  </UModal>
</template>
