<script lang="ts" setup>
import type { PxlIcon } from "~/components/U/Icon";
import type { inputSizes, inputVariants } from "./";

// TODO: Move into separate file when such feature will be supported
interface ICommonInputProps {
  name?: string;
  placeholder?: string;
  required?: boolean;
  loading?: boolean;
  disabled?: boolean;
  icon?: PxlIcon;
  leadingIcon?: PxlIcon;
  trailingIcon?: PxlIcon;
  trailing?: boolean;
  leading?: boolean;
  hasError?: boolean;
  readonly?: boolean;
  size?: keyof typeof inputSizes;
  variant?: keyof typeof inputVariants;
  inputClass?: string;
  minlength?: number;
  maxlength?: number;
  autocomplete?: string;
}

interface InputProps extends ICommonInputProps {
  modelValue?: string | number | Date | null;
  type?: HTMLInputElement["type"];
  min?: HTMLInputElement["min"];
  max?: HTMLInputElement["max"];
  step?: HTMLInputElement["step"];
  inputSize?: HTMLInputElement["size"];
  autofocus?: boolean;
}

const emit = defineEmits(["update:modelValue", "focus", "blur"]);
const props = withDefaults(
  defineProps<InputProps>(),
  {
    size: "lg",
    variant: "default",
    modelValue: "",
    type: "text",
  },
);
const { emitFormBlur, emitFormInput, formGroup } = useFormGroup();
const hasFocus = ref(false);
const inputId = computed(() => formGroup?.name.value || props.name);
const hasError = computed(() => props.hasError || !!formGroup?.error?.value);
const isDisabled = computed(() => props.disabled || !!formGroup?.disabled?.value);

const input = ref<HTMLInputElement | null>(null);

const autoFocus = () => {
  if (props.autofocus) {
    input.value?.focus();
  }
};

const onInput = (event: InputEvent) => {
  emit("update:modelValue", (event.target as HTMLInputElement).value);
  emitFormInput();
};

const onBlur = (event: FocusEvent) => {
  hasFocus.value = false;
  emitFormBlur();
  emit("blur", event);
};
const onFocus = (event: FocusEvent) => {
  hasFocus.value = true;
  emit("focus", event);
};

onMounted(() => setTimeout(() => autoFocus(), 100));
</script>

<template>
  <div class="relative">
    <UInputControl
      :loading="props.loading"
      :disabled="props.disabled"
      :icon="props.icon"
      :leading-icon="props.leadingIcon"
      :trailing-icon="props.trailingIcon"
      :trailing="props.trailing"
      :leading="props.leading"
      :has-error="hasError"
      :has-focus="hasFocus"
      :size="props.size"
      :variant="props.variant"
    >
      <template v-if="$slots.leading" #leading="{ disabled: slotDisabled, loading: slotLoading }">
        <slot name="leading" :disabled="slotDisabled" :loading="slotLoading" />
      </template>

      <template v-if="$slots.trailing" #trailing="{ disabled: slotDisabled, loading: slotLoading }">
        <slot name="trailing" :disabled="slotDisabled" :loading="slotLoading" />
      </template>

      <input
        :id="inputId"
        ref="input"
        class="u-input-field"
        :class="props.inputClass"
        :name="props.name || formGroup?.name.value"
        :value="props.modelValue"
        :type="props.type"
        :min="props.min"
        :max="props.max"
        :minlength="props.minlength"
        :maxlength="props.maxlength"
        :step="props.step"
        :size="props.inputSize"
        :required="props.required"
        :placeholder="props.placeholder"
        :disabled="isDisabled"
        :readonly="props.readonly"
        :autocomplete="props.autocomplete"
        @input="(e) => onInput(e as InputEvent)"
        @focus="onFocus"
        @blur="onBlur"
      />
    </UInputControl>

    <slot />
  </div>
</template>
