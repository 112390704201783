<script setup lang="ts">
import type { IBasicVideo, IEvent, IPlaylist } from "~/types";
import type { PxlIcon } from "~/components/U/Icon";
import { UserModalAuthRequired } from "#components";
import { VIDEO_TYPE } from "~/constants";

const props = withDefaults(
  defineProps<{
    video: IEvent | IBasicVideo;
    type?: "any" | "hd" | "pano";
  }>(),
  {
    type: "any",
  },
);

const { t } = useI18n();
const user = useUser();
const modal = useModal();
const segment = useSegment();
const isEvent = (vid?: IPlaylist | IEvent | IBasicVideo | null): vid is IEvent => vid?.type === VIDEO_TYPE.EVENT;
const icon = computed<PxlIcon>(() => {
  if (props.type === "hd") {
    return {
      name: "download-hd",
      class: "text-neutral-light-700 dark:text-neutral-dark-200 transform translate-x-0.5 scale-125",
    };
  } else if (props.type === "pano") {
    return {
      name: "download-pano",
      class: "text-neutral-light-700 dark:text-neutral-dark-200 transform translate-x-0.5 scale-125",
    };
  }

  return "download";
});
const label = computed(() => {
  if (props.type === "hd") {
    return t("labels.download", { type: "HD" });
  } else if (props.type === "pano") {
    return t("labels.download", { type: "Pano" });
  }

  return t("labels.download");
});
const mp4Url = computed(() => {
  if (props.type === "hd") {
    return props.video.urls.hdMP4;
  } else if (props.type === "pano") {
    return props.video.urls.panoMP4;
  }

  return props.video.urls.hdMP4 || props.video.urls.panoMP4;
});

function onClick() {
  if (user.value) {
    window.open(mp4Url.value, "Download");

    segment.track("Download Video Started", formatTrackVideo(props.video));
  } else {
    modal.open(UserModalAuthRequired);
  }
}

const isAvailable = computed(() => {
  if (props.type === "any" && isEvent(props.video)) {
    // If event was not exported yet - disable the button and show a tooltip with explanation
    return !props.video.urls.hdMP4 && !props.video.urls.panoMP4;
  }

  // Show hd/pano buttons only for events
  // for rest of the videos the regular download button should be shown
  if (props.type === "hd" || props.type === "pano") {
    return isEvent(props.video) && !!mp4Url.value;
  }

  return mp4Url.value;
});

const isDisabled = computed(() => {
  // If event was not exported yet - disable the button and show a tooltip with explanation
  if (isEvent(props.video) && !props.video.urls.hdMP4 && !props.video.urls.panoMP4) {
    return true;
  }

  return false;
});

const tooltip = computed(() => {
  if (props.type === "any" && isEvent(props.video)) {
    if (props.video.permissions.canExport) return t("labels.download_tooltip_text_for_league");
    return t("labels.download_tooltip_text");
  }

  return undefined;
});
</script>

<template>
  <UListItem
    v-if="isAvailable"
    :icon="icon"
    :text="label"
    :disabled="isDisabled"
    :on-click="onClick"
    :tooltip="tooltip"
  />
</template>
