import type { LocationQueryRaw } from "#vue-router";

declare class H3Error extends Error {
  static __h3_error__: boolean;
  toJSON(): Pick<H3Error, "data" | "statusCode" | "statusMessage" | "message">;
  statusCode: number;
  fatal: boolean;
  unhandled: boolean;
  statusMessage?: string;
  data?: any;
}
interface AtlitError extends H3Error {
  data: AtlitErrorResponse;
}
interface AtlitErrorResponse {
  errors: {
    details: string;
    status: string;
    title: string;
    statusCode?: string;
  }[];
}

export function useSingleton<T>() {
  const key = Symbol("singleton");
  return [
    function provide(v: T) {
      const vm = getCurrentInstance();
      vm?.appContext.app.provide(key, v);
    },
    function use(fallback?: T) {
      return inject(key, fallback) as T;
    },
  ] as const;
}

const noop = (s: any) => s;
const isError = (error: unknown): error is Error => error instanceof Error;
// @ts-expect-error ...
export const isH3Error = (error: unknown): error is H3Error => isError(error) && Boolean(error.statusCode);
export const isAtlitError = (error: H3Error): error is AtlitError => Array.isArray(error.data.errors);

export function parseErrorMessage(error?: unknown, opts?: { t?: (s: string) => string }): string {
  try {
    const t = opts?.t || noop;

    if (isH3Error(error) && isAtlitError(error))
      return (error.data.errors[0] && error.data.errors[0].details) || error.message;
    else if (isH3Error(error) && error.data?.message) return error.data.message;
    else if (isH3Error(error)) return error.message;
    // @ts-expect-error `error.code`
    else if (error instanceof Error && error.code)
      // @ts-expect-error `error.code`
      return t(`error_code.${error.code}`);
    else if (isError(error)) return (error as Error).message;
    else if (typeof error === "string") return error;

    return "An unknown error has occurred.";
  } catch (e) {
    console.error(e);
    return "An unknown error has occurred.";
  }
}

export function createMapFrom<T>(arr: T[], key: keyof T): Map<string, T> {
  const map = new Map();
  for (let i = 0; i < arr.length; i++) map.set(arr[i][key], arr[i]);

  return map;
}

export function createMapArrayFrom<T>(arr: T[], key: keyof T): Map<string, T[]> {
  const map = new Map();
  for (let i = 0; i < arr.length; i++) {
    if (map.has(arr[i][key])) map.get(arr[i][key]).push(arr[i]);
    else map.set(arr[i][key], [arr[i]]);
  }
  return map;
}

export function useSearchInArray<T>(arr: T[], params: { q: string; key?: (keyof T | keyof T[]); exact?: boolean }): T[] {
  if (!params.q) return arr;

  const isMatchingSearch = (title: string | number) => {
    if (params.exact) return title === params.q;

    return `${title}`.toLowerCase().includes(params.q.toLowerCase().trim());
  };

  return arr.filter((option) => {
    if (typeof option === "string" || typeof option === "number")
      return isMatchingSearch(option); // In case of primitive search - ignore `key` argument
    else if (typeof option === "object" && option !== null)
      if (Array.isArray(params.key))
        // @ts-expect-error FIXME: Check for other possible types of `option` argument
        return params.key.some((k: keyof T) => isMatchingSearch(option[k]));
      else if (params.key)
        // @ts-expect-error FIXME: Check for other possible types of `option` argument
        return isMatchingSearch(option[params.key as keyof T]);
    return true;
  });
}

export function getUniqueBy<T>(items: T[], prop: keyof T): T[] {
  const uniqueValues: Set<T[keyof T]> = new Set();
  const filteredItems: T[] = [];

  items.forEach((item) => {
    const value = item[prop];
    if (!uniqueValues.has(value)) {
      uniqueValues.add(value);
      filteredItems.push(item);
    }
  });

  return filteredItems;
}

export function getModifiedState<T extends { [key: string]: any }>(currentState: T, initialState: T): Partial<T> {
  const modifiedState = Object.fromEntries(
    Object.entries(currentState).filter(([key, value]) => {
      // Will handle only 2-nd level object comparison
      if (typeof value === "object") {
        const state = initialState[key as keyof T];
        return Boolean(Object.entries(value).filter(([k, v]) => v !== state[k]).length);
      }

      return value !== initialState[key as keyof T];
    }),
  );

  return modifiedState as Partial<T>;
}

export const capitalize = (str: string) => str && str.charAt(0).toUpperCase() + str.slice(1);

export function navigateToLogin(queryParams?: LocationQueryRaw) {
  const route = useRoute();

  if (route.path == "/auth/login") {
    return Promise.resolve();
  }

  return navigateTo({
    path: "/auth/login",
    query: {
      ...queryParams,
      redirectUrl: route.fullPath,
    },
  });
}
